import { useFlyoutContext } from "providers/FlyoutProvider";
import { forwardRef } from "react";
import { useAdvisorFlyOutStyles } from "../AdvisorFlyOut.styles";
import { STANDARD_CONTENT_WIDTH } from "../constants";
import { x } from "@xstyled/styled-components";

export const StandardContent = forwardRef(({ children, showMobileView, ...props }, ref) => {
  const { flyoutConfiguration } = useFlyoutContext();
  const { standardContent } = useAdvisorFlyOutStyles({ flyoutConfiguration, showMobileView });

  return (
    <x.div ref={ref} {...standardContent} w={`${STANDARD_CONTENT_WIDTH}px`} {...props}>
      {children}
    </x.div>
  );
});
