import { FC, useRef } from "react";
import {
  AlphaNowContentType,
  AlphaNowProductType,
  RecommendationCard,
  ContentCardHeader,
} from "@alphasights/client-portal-shared";

import { useAppSearchContext } from "providers/AppSearchProvider";
import {
  getAngleType,
  getParsedDescription,
  getCardTitle,
} from "pages/AlphaNowPage/components/RecommendedContentCard/utils";
import {
  StyledCardDescription,
  StyledCardTitle,
  SubtitleContainer,
} from "pages/AlphaNowPage/components/RecommendedContentCard/RecommendedContentCard.styled";
import {
  PILL_END_BUFFER,
  RESEARCH_TYPE_DESCRIPTION_PREFIX,
} from "pages/AlphaNowPage/components/RecommendedContentCard/consts";
import { RecommendedContent } from "pages/AlphaNowPage/components/RecommendedContentCard/types";
import Experts from "pages/AlphaNowPage/components/Experts";
import CompanyLogos from "pages/AlphaNowPage/components/CompanyLogos";
import { getContentTitle } from "content/AlphaNow/utils";

type RecommendedContentCardProps = {
  content: RecommendedContent;
  onClick: () => void;
};

const Header = ({ content }: { content: RecommendedContent }) => {
  const {
    query: { searchQuery },
  } = useAppSearchContext();

  const { contentType } = content;
  const isPrimer = contentType === AlphaNowContentType.srm;
  return (
    //@ts-ignore
    <ContentCardHeader
      content={content}
      searchQuery={searchQuery}
      isPrimer={isPrimer}
      angleType={getAngleType(content)}
    />
  );
};

const SubTitleContent = ({ content }: { content: RecommendedContent }) => {
  const { contentType, description, speakers } = content;
  const isPrimer = contentType === AlphaNowContentType.srm;
  const cardDescription = isPrimer
    ? getParsedDescription(content)
    : `${RESEARCH_TYPE_DESCRIPTION_PREFIX[contentType]} ${getParsedDescription(content)}`;
  const ref = useRef(null);

  return (
    <SubtitleContainer ref={ref}>
      <StyledCardDescription component="span">{cardDescription}</StyledCardDescription>
      <Experts
        containerRef={ref}
        customBuffer={PILL_END_BUFFER}
        contentType={contentType}
        primerSpeakerTitle={description}
        speakers={speakers}
      />
    </SubtitleContainer>
  );
};

const RecommendedContentCard: FC<RecommendedContentCardProps> = ({ content, onClick }) => {
  const { contentType, productType, companies, externalTitle, primer, companyLogos } = content;
  const isPrimer = contentType === AlphaNowContentType.srm;
  const logos = productType === AlphaNowProductType.companyPrimer ? [primer?.logoLink?.value] : companyLogos;

  return (
    <RecommendationCard
      headerContent={<Header content={content} />}
      titleContent={<StyledCardTitle variant="body-em">{getCardTitle(content)}</StyledCardTitle>}
      subtitleContent={<SubTitleContent content={content} />}
      logo={
        isPrimer && (
          <CompanyLogos
            productType={productType as AlphaNowProductType}
            title={getContentTitle(productType, companies, externalTitle)}
            logos={logos as string[]}
          />
        )
      }
      onClick={onClick}
    />
  );
};

export { RecommendedContentCard as default };
