import { useThemeTokens } from "@alphasights/alphadesign-components";
import { ApexOptions } from "apexcharts";
import { YAxisValueType } from "./YAxis";
import {
  CHART_TYPES,
  OneDimensionalBarChartResults,
  TwoDimensionalStackedBarChartResults,
  TwoDimensionalStackedBarChartNPSResults,
  TwoDimensionalStackedBarChartMedianCategoryResults,
} from "views/SurveysResultsView/api/types";
import { getIntervalledColors } from "views/SurveysResultsView/components/DataVisualization/utils/utils";

export type ChartTypeToResults = {
  [CHART_TYPES.ONE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT]: OneDimensionalBarChartResults;
  [CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART]: TwoDimensionalStackedBarChartResults;
  [CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING]: TwoDimensionalStackedBarChartResults;
  [CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_LIKERT]: TwoDimensionalStackedBarChartResults;
  [CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL]: TwoDimensionalStackedBarChartResults;
  [CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_NET_PROMOTER_SCORE]: TwoDimensionalStackedBarChartNPSResults;
  [CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC]: TwoDimensionalStackedBarChartResults;
  [CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT]: TwoDimensionalStackedBarChartResults;
  [CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_DIVERGENT]: TwoDimensionalStackedBarChartResults;
  [CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL]: TwoDimensionalStackedBarChartResults;
  [CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_CATEGORICAL]: TwoDimensionalStackedBarChartResults;
};

export type TwoDimensionalStackedBarChartProps<T extends keyof ChartTypeToResults> = {
  chartType: T;
  results: ChartTypeToResults[T];
};

type PropsWithColor<T extends keyof ChartTypeToResults> = TwoDimensionalStackedBarChartProps<T> & { color: any };

type GetLabelsAndValuesReturn = {
  labels: { values: string[]; valueType: YAxisValueType; align: string }[];
  data: { name: string; data: number[] }[];
  chartColors: string[];
};

const getLabelsAndValues = <T extends keyof ChartTypeToResults>({
  chartType,
  results,
  color,
}: PropsWithColor<T>): GetLabelsAndValuesReturn => {
  const defaultChartColors = [
    color.chart.categorical.base03,
    color.chart.categorical.base04,
    color.chart.categorical.base07,
    color.chart.categorical.base,
    color.chart.categorical.base09,
  ];

  const getNPSDotColor = (npsValue: number) => {
    if (npsValue > 0) {
      return color.chart.categorical.base03;
    }
    if (npsValue < 0) {
      return color.chart.categorical.base04;
    }

    return color.chart.categorical.base09;
  };

  const getTwoDimensionalStackedBarChartMedianCategory = (
    results: TwoDimensionalStackedBarChartMedianCategoryResults,
    chartColors: string[]
  ) => {
    const labelsVal: string[] = [];
    const medianResponse: string[] = [];
    const quantities: string[] = [];
    const dataMap: { [key: string]: number[] } = {};
    const data: { name: string; data: number[] }[] = [];

    // Based on the sample size, it returns the colors in intervals, so that a small
    // set of colors has light tones as well as dark tones, in order to use the entire
    // scale, avoiding some intermediate tones.
    const newColors = getIntervalledColors(chartColors, results.aggregated[0].yElements.length);

    // replicate the colors to have the same number of colors as the number of answers
    const replicatedChartColors = [...newColors];

    for (let i = 0; i < results.aggregated[0].yElements.length; i += newColors.length) {
      replicatedChartColors.push(...newColors);
    }

    results.aggregated.forEach((item, index) => {
      const totalExpertsOnRow = item.yElements.reduce((acc, elem) => acc + elem.quantity, 0);

      labelsVal.push(item.x.answerText);
      quantities.push(item.x.quantity.toString());

      item.yElements.forEach((yElement) => {
        if (!dataMap[yElement.answerText]) {
          dataMap[yElement.answerText] = new Array(results.aggregated.length).fill(0);
          data.push({ name: yElement.answerText, data: dataMap[yElement.answerText] });
        }
        dataMap[yElement.answerText][index] = (yElement.quantity * 100) / totalExpertsOnRow;
      });

      const averageAnswer = item.average?.category;
      const answers = item.yElements.map((yElement) => yElement.answerText);
      const averageIndex = answers.indexOf(averageAnswer as string);

      medianResponse.push(`${replicatedChartColors[averageIndex]},${item.average?.category}`);
    });

    return {
      labels: [
        { values: labelsVal, valueType: YAxisValueType.Logo, align: "left" },
        { values: labelsVal, valueType: YAxisValueType.Text, align: "left" },
        {
          values: medianResponse,
          valueType: YAxisValueType.PillColoredCircleAndText,
          align: "left",
        },
        {
          values: quantities,
          valueType: YAxisValueType.Text,
          align: "right",
        },
      ],
      data,
      chartColors: newColors,
    };
  };

  switch (chartType) {
    case CHART_TYPES.ONE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT: {
      const chartColors = ["#D65454", "#CE8D8D", "#C6C6C6", "#80AFA0", "#3B987B"];

      let meanColor = color.chart.categorical.base;
      const resultsData = results as OneDimensionalBarChartResults;

      // replicate the colors to have the same number of colors as the number of answers
      const replicatedChartColors = [...chartColors];

      for (let i = 0; i < resultsData.aggregated.length; i += chartColors.length) {
        replicatedChartColors.push(...chartColors);
      }

      for (let i = 0; i < resultsData.aggregated.length; i++) {
        const item = resultsData.aggregated[i];
        const itemValue = parseFloat(item.answerText);
        const averageValue = resultsData.average?.value ?? 0;
        if (itemValue > averageValue) {
          meanColor = replicatedChartColors[i - 1];
          break;
        }
      }
      return {
        labels: [
          {
            values: [`${meanColor},${(results as OneDimensionalBarChartResults).average?.value?.toFixed(2) ?? 0}`],
            valueType: YAxisValueType.PillColoredCircleAndText,
            align: "left",
          },
        ],
        data: (results as OneDimensionalBarChartResults).aggregated.map((item) => ({
          name: item.answerText,
          data: [item.quantity],
        })),
        chartColors: replicatedChartColors,
      };
    }
    case CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT:
    case CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC: {
      const chartColors = ["#D65454", "#CE8D8D", "#C6C6C6", "#80AFA0", "#3B987B"];

      const labelsVal: string[] = [];
      const medianResponse: string[] = [];
      const dataMap: { [key: string]: number[] } = {};
      const data: { name: string; data: number[] }[] = [];

      (results as TwoDimensionalStackedBarChartMedianCategoryResults).aggregated.forEach((item, index) => {
        const totalExpertsOnRow = item.yElements.reduce((acc, elem) => acc + elem.quantity, 0);

        labelsVal.push(item.x.answerText);

        item.yElements.forEach((yElement) => {
          if (!dataMap[yElement.answerText]) {
            dataMap[yElement.answerText] = new Array(
              (results as TwoDimensionalStackedBarChartMedianCategoryResults).aggregated.length
            ).fill(0);
            data.push({ name: yElement.answerText, data: dataMap[yElement.answerText] });
          }
          dataMap[yElement.answerText][index] = (yElement.quantity * 100) / totalExpertsOnRow;
        });

        const averageAnswer = Math.floor(item.average?.value || 0).toString();
        const answers = item.yElements.map((yElement) => yElement.answerText);
        const averageIndex = answers.indexOf(averageAnswer);

        medianResponse.push(`${chartColors[averageIndex]},${item.average?.value?.toFixed(2)}`);
      });

      return {
        labels: [
          { values: labelsVal, valueType: YAxisValueType.Text, align: "left" },
          {
            values: medianResponse,
            valueType: YAxisValueType.PillColoredCircleAndText,
            align: "left",
          },
        ],
        data,
        chartColors,
      };
    }
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL: {
      const chartColors = [
        "#E3EFFE",
        color.chart.sequential.base08,
        "#B2D3FC",
        "#91C0FB",
        "#6CACFA",
        "#5BA0F9",
        "#5398F8",
        "#4D92F8",
        "#4489F6",
        "#3C82F4",
        "#336BCE",
        "#2953A7",
        "#1F3A7F",
        "#1C3476",
        "#182F6C",
        "#152A64",
        "#122146",
        "#0E1934",
      ];

      return getTwoDimensionalStackedBarChartMedianCategory(
        results as TwoDimensionalStackedBarChartMedianCategoryResults,
        chartColors
      );
    }
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING: {
      const chartColors = [
        "#DC2929",
        "#D65454",
        "#D27171",
        "#CE8D8D",
        "#CAAAAA",
        "#C6C6C6",
        "#A3BBB3",
        "#80AFA0",
        "#5EA38D",
        "#3B987B",
        "#08875F",
      ];

      return getTwoDimensionalStackedBarChartMedianCategory(
        results as TwoDimensionalStackedBarChartMedianCategoryResults,
        chartColors
      );
    }
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART:
    case CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_DIVERGENT:
    case CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL:
    case CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_CATEGORICAL:
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_LIKERT: {
      let chartColors = defaultChartColors;

      const shouldIncludeLogos = [
        CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL,
        CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART,
      ].includes(chartType);
      const labelsVal: string[] = [];
      const dataMap: { [key: string]: number[] } = {};
      const data: { name: string; data: number[] }[] = [];

      (results as TwoDimensionalStackedBarChartResults).aggregated.forEach((item, index) => {
        labelsVal.push(item.x.answerText);

        const totalExpertsOnRow = item.yElements.reduce((acc, elem) => acc + elem.quantity, 0);

        item.yElements.forEach((yElement) => {
          if (!dataMap[yElement.answerText]) {
            dataMap[yElement.answerText] = new Array(
              (results as TwoDimensionalStackedBarChartResults).aggregated.length
            ).fill(0);
            data.push({ name: yElement.answerText, data: dataMap[yElement.answerText] });
          }
          dataMap[yElement.answerText][index] = (yElement.quantity * 100) / totalExpertsOnRow;
        });
      });

      const labels = [];

      if (shouldIncludeLogos) {
        labels.push({
          values: labelsVal,
          valueType: YAxisValueType.Logo,
          align: "left",
        });
      }

      if (
        [
          CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_DIVERGENT,
          CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL,
          CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_CATEGORICAL,
        ].includes(chartType)
      ) {
        chartColors = ["#D65454", "#CE8D8D", "#C6C6C6", "#80AFA0", "#3B987B"];
      }

      return {
        labels: [...labels, { values: labelsVal, valueType: YAxisValueType.Text, align: "left" }],
        data,
        chartColors: chartColors,
      };
    }
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_NET_PROMOTER_SCORE: {
      const npsChartColors = [
        color.chart.categorical.base04,
        color.chart.categorical.base09,
        color.chart.categorical.base03,
      ];
      const labelsVal: string[] = [];
      const nps: string[] = [];
      const quantities: string[] = [];
      const dataMap: { [key: string]: number[] } = {};
      const data: { name: string; data: number[] }[] = [];

      (results as TwoDimensionalStackedBarChartNPSResults).aggregated.forEach((item, index) => {
        const totalExpertsOnRow = item.yElements.reduce((acc, elem) => acc + elem.quantity, 0);

        labelsVal.push(item.x.answerText);
        quantities.push(item.x.quantity.toString());
        nps.push(`${getNPSDotColor(item.netPromoterScore)},${item.netPromoterScore}`);

        item.yElements.forEach((yElement) => {
          if (!dataMap[yElement.answerText]) {
            dataMap[yElement.answerText] = new Array(
              (results as TwoDimensionalStackedBarChartNPSResults).aggregated.length
            ).fill(0);
            data.push({ name: yElement.answerText, data: dataMap[yElement.answerText] });
          }
          dataMap[yElement.answerText][index] = (yElement.quantity * 100) / totalExpertsOnRow;
        });
      });

      return {
        labels: [
          { values: labelsVal, valueType: YAxisValueType.Logo, align: "left" },
          { values: labelsVal, valueType: YAxisValueType.Text, align: "left" },
          {
            values: nps,
            valueType: YAxisValueType.PillColoredCircleAndText,
            align: "left",
          },
          {
            values: quantities,
            valueType: YAxisValueType.Text,
            align: "right",
          },
        ],
        data,
        chartColors: npsChartColors,
      };
    }
    default:
      return {
        labels: [{ values: [], valueType: YAxisValueType.Text, align: "left" }],
        data: [],
        chartColors: defaultChartColors,
      };
  }
};

export const useChartConfig = <T extends keyof ChartTypeToResults>({
  chartType,
  results,
}: TwoDimensionalStackedBarChartProps<T>) => {
  const { color, font, typography } = useThemeTokens();
  const { labels, data, chartColors } = getLabelsAndValues({ chartType, results, color });

  return {
    labels,
    values: data,
    legendData: data.map(({ name }, index) => ({
      color: chartColors[index % chartColors.length],
      label: name,
    })),
    chartHeight: data[0].data.length * 40 + 30,
    config: {
      chart: {
        animations: { enabled: false },
        fontFamily: font.family.text.regular,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        parentHeightOffset: 0,
        offsetX: 0,
        offsetY: 0,
      },
      colors: chartColors,
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: { left: 0, right: 0, top: -30, bottom: -15 },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            enabled: false,
          },
          barHeight: "28px",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        title: {
          txt: "Customers",
          style: {
            fontWeight: typography.body.small.fontWeight,
            fontSize: typography.body.small.fontSize,
            color: color.text.strong._,
          },
          offsetY: 15,
        },
        max: 100,
        axisBorder: {
          show: false,
        },
        tickAmount: 10,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: undefined,
      },
      legend: {
        show: false,
      },
      annotations: {
        xaxis: [
          CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING,
          CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL,
        ].includes(chartType)
          ? [
              {
                x: 50,
                strokeDashArray: 10,
                offsetY: 6,
                borderColor: "#000",
              },
            ]
          : [],
      },
      // this avoids the color to be darker when clicking on an entry
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
      },
    } as ApexOptions,
  };
};
