import { useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useAdvisorFlyOutStyles } from "./AdvisorFlyOut.styles";
import { Topbar } from "./sections/Topbar/Topbar";
import useWindowDimensions from "hooks/useWindowDimensions";
import { FlyoutProvider } from "../../providers/FlyoutProvider";
import { useAdvisorFlyoutReducer } from "./AdvisorFlyOutReducer";
import { FlyoutConfiguration, FlyoutMode } from "./enums";
import { MessagesFlyout } from "./MessagesFlyout";
import { AdvisorFlyout } from "./AdvisorFlyout";
import { useFlyoutConfiguration } from "./useFlyoutConfiguration";
import { Container } from "./elements/Container";
import { Body } from "./elements/Body";
import { EXTENDED_CONTENT_WIDTH, MIN_CONTENT_WIDTH, STANDARD_CONTENT_WIDTH } from "./constants";
import { StandardContent } from "./elements/StandardContent";
import { Header } from "./elements/Header";

export const AdvisorSidebar = ({ isOpen, onClose, flyoutMode, showMobileView, showChainList = false, ...props }) => {
  const width = useFlyoutWidth(flyoutMode);
  const { sidebar } = useAdvisorFlyOutStyles();

  return (
    <>
      {isOpen && props.interaction && (
        <x.div w={`${width}px`} {...sidebar}>
          <AdvisorFlyout
            flyoutMode={flyoutMode}
            onClose={onClose}
            showChainList={showChainList}
            showMobileView={showMobileView}
            {...props}
          />
        </x.div>
      )}
    </>
  );
};

export const NewMessageSidebar = ({ onClose, ...props }) => {
  const width = useFlyoutWidth(FlyoutMode.NewMessage);
  const { sidebar } = useAdvisorFlyOutStyles();
  return (
    <x.div w={`${width}px`} {...sidebar}>
      <NewMessageFlyout onClose={onClose} showChainList={false} {...props} />
    </x.div>
  );
};

const NewMessageFlyout = ({ newMessageType, newMessageOrigin, onSendNewMessage, selectedExpertsIds, ...props }) => {
  const { token: projectToken, hasClientPortalMessages, allInteractions } = props;
  const [flyoutState, dispatchFlyout] = useAdvisorFlyoutReducer();

  const [maximumTaskDuration, setMaximumTaskDuration] = useState();
  const [selectedExperts, setSelectedExperts] = useState([]);

  const sendMessageButtonRef = useRef();

  const flyoutConfiguration = useFlyoutConfiguration(FlyoutMode.NewMessage);

  return (
    <FlyoutProvider
      dispatch={dispatchFlyout}
      state={flyoutState}
      projectToken={projectToken}
      flyoutAction={props.flyoutAction}
      flyoutMode={FlyoutMode.NewMessage}
      flyoutConfiguration={flyoutConfiguration}
      hasClientPortalMessages={hasClientPortalMessages}
      newMessageType={newMessageType}
      newMessageOrigin={newMessageOrigin}
      allInteractions={allInteractions}
      selectedExpertsIds={selectedExpertsIds}
    >
      <Container>
        <Body>
          <StandardContent>
            <x.div data-testid="messages-flyout">
              <MessagesFlyout
                isProjectCidEnabled={props.isProjectCidEnabled}
                sendMessageButtonRef={sendMessageButtonRef}
                maximumTaskDuration={maximumTaskDuration}
                setMaximumTaskDuration={setMaximumTaskDuration}
                setSelectedExperts={setSelectedExperts}
              />
            </x.div>
          </StandardContent>
        </Body>
        <Header>
          <Topbar
            {...props}
            chainInteractions={[]}
            onSendNewMessage={onSendNewMessage}
            sendMessageButtonRef={sendMessageButtonRef}
            maximumTaskDuration={maximumTaskDuration}
            selectedExperts={selectedExperts}
          />
        </Header>
      </Container>
    </FlyoutProvider>
  );
};

export const useFlyoutWidth = (flyoutMode) => {
  const configuration = useFlyoutConfiguration(flyoutMode);
  const width =
    configuration === FlyoutConfiguration.Standard
      ? STANDARD_CONTENT_WIDTH
      : STANDARD_CONTENT_WIDTH + EXTENDED_CONTENT_WIDTH;

  const [windowWidth] = useWindowDimensions();

  if (configuration === FlyoutConfiguration.Fullscreen || windowWidth <= width + MIN_CONTENT_WIDTH) {
    return windowWidth;
  }

  return width;
};
