import { useAdvisorFlyOutStyles } from "../AdvisorFlyOut.styles";
import { x } from "@xstyled/styled-components";

export const Container = ({ children }) => {
  const { container } = useAdvisorFlyOutStyles();

  return (
    <x.div {...container} data-testid="advisor-flyout">
      {children}
    </x.div>
  );
};
