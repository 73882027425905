import { Carousel, CarouselVariant } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Typography } from "@alphasights/alphadesign-components";
import { RecommendedContentCardCarouselContainer } from "pages/AlphaNowPage/components/RecommendedContentCardCarousel/RecommendedContentCardCarousel.styled";
import {
  NUMBER_CONTENT_CARDS_VISIBLE_DESKTOP,
  NUMBER_CONTENT_CARDS_VISIBLE_ULTRA_WIDE,
} from "pages/AlphaNowPage/components/RecommendedContentCardCarousel/consts";
import RecommendedContentCard, { RecommendedContent } from "pages/AlphaNowPage/components/RecommendedContentCard";

const DATA_TEST_IDS = {
  recommendedContentCardCarousel: "recommended-content-card-carousel",
};

type SelectedContentProps = {
  content: RecommendedContent;
  cardPosition: number;
  title: string;
};

type RecommendedContentCardCarouselProps = {
  title: string;
  data: RecommendedContent[];

  dataTestId?: string;
  onClick: ({ content, cardPosition, title }: SelectedContentProps) => void;
};

const RecommendedContentCardCarousel = ({
  title,
  data,
  dataTestId = DATA_TEST_IDS.recommendedContentCardCarousel,
  onClick,
}: RecommendedContentCardCarouselProps) => {
  const { isUltraWide } = useCheckScreen();

  const handleSelectContent = (content: RecommendedContent, cardPosition: number) =>
    onClick({ content, cardPosition, title });

  return (
    <RecommendedContentCardCarouselContainer data-testid={dataTestId}>
      <Carousel
        variant={CarouselVariant.basic}
        numberOfItemsDisplayed={
          isUltraWide ? NUMBER_CONTENT_CARDS_VISIBLE_ULTRA_WIDE : NUMBER_CONTENT_CARDS_VISIBLE_DESKTOP
        }
        title={<Typography variant="body-large-em">{title}</Typography>}
      >
        {data.map((content, index) => (
          <RecommendedContentCard content={content} onClick={() => handleSelectContent(content, index)} />
        ))}
      </Carousel>
    </RecommendedContentCardCarouselContainer>
  );
};

export { RecommendedContentCardCarousel as default, DATA_TEST_IDS };
