import { useCallback, useContext, useEffect, useState } from "react";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { useLocation, useNavigate } from "router-utils";
import { changeInteractionChainDate, loadAdvisorInteractions, loadUpgrades } from "components/InteractionsPage/reducer";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { parseISO } from "date-fns";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

export const useChainedInteractions = ({
  chainInteractions,
  interaction,
  projectToken,
  backgroundSyncRunning,
  onChainIdSelectedUpdated,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useIsAuthenticated();
  const [chainIdSelected, setChainIdSelected] = useState();
  const dispatch = useContext(DispatchContext);
  const {
    state: { runningAction },
  } = useProjectInteractionsContext();

  const { id: interactionId, advisorId, group } = interaction;

  useEffect(() => {
    if (dispatch && !backgroundSyncRunning) {
      onUpdateChainIdSelected(interactionId);
      dispatch(
        loadAdvisorInteractions({
          interactionId,
          advisorId,
          projectToken,
          groupId: group?.id,
        })
      );
    }
  }, [interaction.id, backgroundSyncRunning]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chainInteractions.length === 0 || runningAction === "loadingAdvisorInteractions" || !location) return;

    const selected = new URLSearchParams(location.search).get("selected");

    if (chainInteractions.find(({ id }) => id === selected)) {
      onUpdateChainIdSelected(selected);
    } else {
      onUpdateChainIdSelected(interaction.id);
    }
  }, [JSON.stringify(chainInteractions)]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClearSelectedIdChainURL = useCallback(() => {
    if (!location || !navigate) {
      return;
    }
    const search = new URLSearchParams(location.search);
    search.delete("selected");
    navigate(
      {
        search: "?" + search.toString(),
      },
      { replace: true }
    );
  }, [location, navigate]);

  useEffect(() => {
    onClearSelectedIdChainURL();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setInteractionChainDate = useCallback((currentDate) => dispatch(changeInteractionChainDate(currentDate)), [
    dispatch,
  ]);

  const onUpdateChainIdSelected = useCallback(
    (id) => {
      if (id !== chainIdSelected) {
        setChainIdSelected(id);
        onChainIdSelectedUpdated && onChainIdSelectedUpdated(id);
        isLoggedIn && dispatch(loadUpgrades({ projectToken, interactionId: id }));
      }

      if (id === interaction.id) {
        interaction.scheduledCallTime && setInteractionChainDate(parseISO(interaction.scheduledCallTime));
        onClearSelectedIdChainURL();
      } else {
        const period = chainInteractions.find((chain) => chain.id === id)?.scheduledCallTime;
        chainInteractions.length > 1 && period && setInteractionChainDate(parseISO(period));

        const search = new URLSearchParams(location.search);
        search.set("selected", id);
        if (navigate) {
          navigate(
            {
              search: "?" + search.toString(),
            },
            { replace: true }
          );
        }
      }
    },
    [
      isLoggedIn,
      chainIdSelected,
      chainInteractions,
      dispatch,
      location,
      interaction.id,
      interaction.scheduledCallTime,
      onChainIdSelectedUpdated,
      onClearSelectedIdChainURL,
      projectToken,
      setInteractionChainDate,
      navigate,
    ]
  );

  return { chainIdSelected, onUpdateChainIdSelected };
};
