import { useFlyoutContext } from "providers/FlyoutProvider";
import { forwardRef } from "react";
import { useAdvisorFlyOutStyles } from "../AdvisorFlyOut.styles";
import { x } from "@xstyled/styled-components";

export const Body = forwardRef(({ children, showMobileView, ...props }, ref) => {
  const { flyoutMode } = useFlyoutContext();
  const { body } = useAdvisorFlyOutStyles({ showMobileView, flyoutMode });

  return (
    <x.div ref={ref} {...body} {...props}>
      {children}
    </x.div>
  );
});
