import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { FlyoutConfiguration } from "../enums";
import { useAdvisorFlyOutStyles } from "../AdvisorFlyOut.styles";
import { EXTENDED_CONTENT_WIDTH } from "../constants";

export const ExtendedContent = ({ flyoutConfiguration, showMobileView, children }) => {
  const { extendedContent, extendedContentMobile } = useAdvisorFlyOutStyles({
    flyoutConfiguration,
    showMobileView,
  });
  const { isMobile } = useCheckScreen();

  const width = flyoutConfiguration === FlyoutConfiguration.Extended ? EXTENDED_CONTENT_WIDTH : 0;

  return (
    <x.div
      {...extendedContent}
      {...(isMobile && flyoutConfiguration === FlyoutConfiguration.Extended ? extendedContentMobile : {})}
      w={!isMobile && `${width}px`}
    >
      {width > 0 && children}
    </x.div>
  );
};
