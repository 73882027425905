import { enrichInteraction as enrichInteractionHelper } from "components/InteractionsPage/helpers/Interaction";
import { differenceInMinutes, isWithinInterval, parseISO } from "date-fns";

export const getFirstName = (name) => {
  return name ? name.toString().split(" ")[0] : "";
};

export const getThreadStateColorFlyout = (state, colorTokens) => {
  if (state) {
    const colors = [
      { states: ["REPLIED"], color: colorTokens.icon.success },
      { states: ["SENT"], color: colorTokens.icon.success },
      {
        states: ["WAITING_RESPONSE", "PENDING_UPDATE", "AWAITING_APPROVAL"],
        color: colorTokens.icon.processing,
      },
    ];

    return colors.find((color) => color.states.includes(state.code))?.color ?? colorTokens.icon.secondary;
  }
};

export const onGracePeriod = (interaction) => {
  return interaction?.scheduledAt && differenceInMinutes(new Date(), parseISO(interaction.scheduledAt)) < 6;
};

export const dateIntervalContains = (start, end, date) => {
  if (!start || !end || !date) return false;

  return isWithinInterval(date, {
    start: start instanceof Date ? start : parseISO(start),
    end: end instanceof Date ? end : parseISO(end),
  });
};
export const enrichInteraction = ({
  interaction,
  clientPccFlag,
  isActiveProject = true,
  pendingRequest,
  isMobile,
  hasClientPortalMessages,
  isSendMessagesDisabled,
  isInteractionActionsDisabled,
  ...props
}) => {
  const richInteraction = enrichInteractionHelper(interaction, isInteractionActionsDisabled);

  const cancelInteractionRequest = pendingRequest?.type === "CANCEL_REQUEST" ? pendingRequest : null;
  return {
    ...richInteraction,
    angleId: interaction.angles?.at(0)?.id,
    showFollowUpMessageButton:
      richInteraction.state === "completed" &&
      richInteraction.isActiveProject &&
      !richInteraction.isSurvey &&
      (richInteraction.showEmailButton || (richInteraction.canSendFollowUpMessage && hasClientPortalMessages)) &&
      !isSendMessagesDisabled,
    showLeaveAvailability:
      richInteraction.showLeaveAvailability &&
      !richInteraction.hasAdvisorAvailability &&
      !isMobile &&
      !isInteractionActionsDisabled,
    showCancelRequestMenu:
      richInteraction.canCancel && richInteraction.state === "requested" && !isInteractionActionsDisabled,
    showPccMenu: richInteraction.state !== "proposed" && clientPccFlag && !cancelInteractionRequest,
    showRequestToReEngage: !isActiveProject,
    showUpgradeInteraction:
      isActiveProject &&
      richInteraction.state === "scheduled" &&
      !cancelInteractionRequest &&
      !richInteraction.previousAdvisorshipId,
    showMessagesLink: !richInteraction.isSurvey && richInteraction.isActiveProject && !richInteraction.noWrittenWork,
    showMessage:
      (richInteraction.state === "requested" && !richInteraction.showSchedule) || richInteraction.state === "scheduled",
    ...props,
  };
};
export const isAvailabilityVisible = (isActiveProject, interaction) =>
  isActiveProject &&
  (["requested", "scheduled"].includes(interaction.state) ||
    (["proposed", "completed"].includes(interaction.state) && interaction.advisorAvailability?.length > 0)) &&
  !interaction.isSurvey;
