import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { loadAdvisorResearchRequest } from "components/InteractionsPage/reducer";
import { useContext, useState, useEffect } from "react";

export const useResearchRequest = ({ researchRequest, interaction, isActiveProject, projectToken }) => {
  const dispatch = useContext(DispatchContext);
  const [request, updateResearchRequest] = useState(researchRequest);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    updateResearchRequest(researchRequest);
  }, [interaction.id, researchRequest]);

  useEffect(() => {
    setIsLoading(true);
    if (!isActiveProject && interaction.id && dispatch) {
      dispatch(
        loadAdvisorResearchRequest({
          projectToken,
          interactionId: interaction.id,
        })
      ).then(() => setIsLoading(false));
    }
  }, [interaction.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return [request, updateResearchRequest, isLoading];
};
