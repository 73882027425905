import { Icon } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";
import { useRelevantCompanyLogo } from "app/services/useRelevantCompanyLogo";
import { useFlags } from "app/wrappers/FeatureFlagsWrapper";
import { Skeleton } from "@alphasights/alphadesign-components";
import styled from "styled-components";
import { th } from "@xstyled/styled-components";
import { useIsConsultingClientProject } from "app/services/useProject";

export const COMPANY_LOGO_SIZE_LARGE = 32;
export const COMPANY_LOGO_SIZE_MEDIUM = 24;
export const COMPANY_LOGO_SIZE_SMALL = 16;

const RelevantCompanyLogoContainer = styled.div<{ size?: number }>`
  width: ${({ size }) => size ?? COMPANY_LOGO_SIZE_LARGE}px;
  height: ${({ size }) => size ?? COMPANY_LOGO_SIZE_LARGE}px;
  min-width: ${({ size }) => size ?? COMPANY_LOGO_SIZE_LARGE}px;

  border-radius: ${th.radius("8")};
  border: ${th.borderWidth("sm")} solid ${th.color("border-disabled")};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Loading = () => (
  <div>
    <Skeleton circle />
  </div>
);

export type RelevantCompanyLogoProps = {
  projectToken: string;
  interactionId: string;
  size?: "large" | "medium" | "small" | number;
} & React.HTMLAttributes<HTMLDivElement>;

export const RelevantCompanyLogo = ({
  projectToken,
  interactionId,
  size = "large",
  ...props
}: RelevantCompanyLogoProps) => {
  const { enableCdsLogos } = useFlags();
  const isConsultingClient = useIsConsultingClientProject(projectToken);
  const logoQuery = useRelevantCompanyLogo(projectToken, interactionId, enableCdsLogos);

  const sizeMap = {
    large: COMPANY_LOGO_SIZE_LARGE,
    medium: COMPANY_LOGO_SIZE_MEDIUM,
    small: COMPANY_LOGO_SIZE_SMALL,
  };
  const logoSize = typeof size === "number" ? size : sizeMap[size];

  if (!enableCdsLogos) {
    return null;
  }

  if (isConsultingClient) {
    return null;
  }

  const { data, isLoading } = logoQuery;

  return (
    <RelevantCompanyLogoContainer size={logoSize} {...props}>
      {isLoading && <Loading />}
      {!isLoading && data && <img src={data} alt="Company logo" />}
      {!isLoading && !data && (
        <Icon size="large">
          <Company />
        </Icon>
      )}
    </RelevantCompanyLogoContainer>
  );
};
