import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { HitOrigin } from "@alphasights/portal-api-client";
import useWatchlist from "hooks/useWatchlist";
import {
  WATCHLIST_TITLE,
  MANAGE_WATCHLIST_BUTTON,
} from "pages/AlphaNowPage/components/LandingPage/WatchlistSection/constants";
import useModal from "hooks/useModal";
import WatchlistModal from "components/WatchlistModal";

import { WatchlistHeaderContainer, WatchlistTitleContainer } from "./WatchlistHeader.styled";

const WatchlistHeader = ({ watchlistName, watchlistId }: { watchlistName: string; watchlistId?: string }) => {
  const { color } = useThemeTokens();
  const { isVisible, onOpen, onClose } = useModal();
  const { availableWatchlists, selectedWatchlistId, setSelectedWatchlistId } = useWatchlist();

  const handleSelectWatchlist = () => {
    setSelectedWatchlistId(watchlistId);
    onOpen();
  };

  return (
    <WatchlistHeaderContainer>
      <WatchlistTitleContainer>
        <Typography variant="body-small" color={color.text.secondary}>
          {WATCHLIST_TITLE}
        </Typography>
        <Typography variant="body-em">{watchlistName}</Typography>
      </WatchlistTitleContainer>
      <Button variant="outline" size="small" onClick={handleSelectWatchlist}>
        {MANAGE_WATCHLIST_BUTTON}
      </Button>
      {isVisible && (
        <WatchlistModal
          selectedWatchlistId={selectedWatchlistId}
          setSelectedWatchlistId={setSelectedWatchlistId}
          availableWatchlists={availableWatchlists}
          onClose={onClose}
          origin={HitOrigin.landingPage}
        />
      )}
    </WatchlistHeaderContainer>
  );
};

export default WatchlistHeader;
