import { FlyoutMode, FlyoutConfiguration } from "./enums";

export const useFlyoutConfiguration = (flyoutMode) => {
  switch (flyoutMode) {
    case FlyoutMode.Schedule:
    case FlyoutMode.RequestReschedule:
    case FlyoutMode.RequestAvailability:
    case FlyoutMode.ProvideAvailability:
    case FlyoutMode.Calendar:
      return FlyoutConfiguration.Fullscreen;

    case FlyoutMode.Comments:
      return FlyoutConfiguration.Extended;

    default:
      return FlyoutConfiguration.Standard;
  }
};
