import { HighlightTextTypography } from "components/AdvisorCard/HighlightText";
import { ExpertTableRow } from "models/ExpertTable";
import { HideableCell } from "./HideableCell";
import { RelevantCompanyLogo } from "components/RelevantCompanyLogo";
import styled from "styled-components";
import { th } from "@xstyled/styled-components";

const CellContainer = styled(HideableCell)`
  display: flex;
  flex-wrap: nowrap;
  gap: ${th.space("layout-base")};
  align-items: center;
`;

export const RelevantCompanyColumn = ({ row: { original } }: { row: ExpertTableRow }) => {
  const { id, highlights, advisorCompany, hidden, doubleBlinded } = original;

  return (
    <CellContainer hidden={hidden}>
      <RelevantCompanyLogo projectToken={original.projectToken} interactionId={original.id} size="large" />
      <HighlightTextTypography
        data-testid={`advisor-row-company-${id}`}
        variant="body-small"
        component="span"
        highlights={highlights}
        fieldNames={[
          "advisorships.relevant_company_name",
          "advisorships.relevant_company_name.concat",
          "relevantPrimaryJob.companyName",
          "relevantPrimaryJob.companyName.concat",
        ]}
        text={doubleBlinded ? "Company name withheld" : advisorCompany}
        preventLineBreak
      />
    </CellContainer>
  );
};
