import { Link, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { TIME_FORMAT } from "helpers/interactionHelpers";
import { usePrivateLibrary } from "hooks/usePrivateLibrary";
import { ExpertTableRow } from "models/ExpertTable";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { useMemo } from "react";
import { hasDeliverable } from "views/DeliverablesView/helpers";

export const InteractionDetailsColumn = ({ row }: { row: ExpertTableRow }) => {
  const { getPrivateLibraryItemFromInteraction } = usePrivateLibrary();

  const interaction = row.original;

  const relatedPrivateLibraryItem = useMemo(() => getPrivateLibraryItemFromInteraction(interaction), [
    getPrivateLibraryItemFromInteraction,
    interaction,
  ]);

  const linkToTranscript = useMemo(() => {
    return `/${interaction.projectToken}/experts/deliverables-view/?transcriptType=Transcript&selectedInteraction=${interaction.id}`;
  }, [interaction.id, interaction.projectToken]);

  const showTranscriptLink = useMemo(() => hasDeliverable(interaction, relatedPrivateLibraryItem), [
    interaction,
    relatedPrivateLibraryItem,
  ]);

  return (
    <x.div data-testid={`interaction-${interaction.id}-details`}>
      {interaction?.scheduledCallTime && (
        <Typography variant="body-small" component={"div"}>
          <FormattedDateTime date={interaction?.scheduledCallTime} format={`d MMM, ${TIME_FORMAT}`} />
        </Typography>
      )}
      {showTranscriptLink && (
        <Link href={linkToTranscript} onClick={(e) => e.stopPropagation()} size="small" fontWeight="bold !important">
          View Transcript
        </Link>
      )}
    </x.div>
  );
};
